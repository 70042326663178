@import '../../../../../styles/variables';

.names {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	display: block;

	&::before {
		content: '\2610';
		display: inline-block;
		margin-right: 0.25rem;
		font-size: 1rem;
		vertical-align: text-top;
	}

	+ .names {
		margin-top: 0.25rem;
	}
}

.list {
	margin: -0.5rem 0;
}

.buttons {
	.checkbox {
		grid-column: 1;
		justify-self: start;
	}
}
