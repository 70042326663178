@import '../../../styles/variables';

.container {
	margin: 1rem 0;
	gap: 1rem;

	.copy {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.button {
		max-height: 3rem;
		margin: auto 0;
	}

	.copyAndPrimaryButtons {
		display: flex;
		column-gap: 1rem;

		&.multipleButtons {
			display: grid;
			grid-template-areas:
				'copy button1'
				'copy button2';

			.copy {
				grid-area: copy;
			}

			.button {
				grid-area: button2;

				&:last-child {
					grid-area: button1;
				}
			}

			@media ( min-width: $desktop ) {
				display: flex;
				grid-template-areas: unset;
				column-gap: 0;

				.copy,
				.button {
					grid-area: unset;
				}
			}
		}
	}
}

.avatar {
	background-color: $lightGray;
	padding: 1rem;
	min-width: 2.5rem;
	min-height: 2.5rem;
	@media ( min-width: $desktop ) {
		min-width: 4rem;
		min-height: 4rem;
	}
}

.timestamp {
	color: $readableGray;
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
	min-width: 1.5rem;
	min-height: 1.5rem;
	fill: $darkBlue;
}
