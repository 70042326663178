#page-reset-password {
	margin-bottom: 0;

	&.reset-password__container {
		display: flex;
		flex-direction: column;
		padding: 0;

		@media ( min-width: #{$desktop} ) {
			width: 100%;
			max-width: 100vw;
			background-image: url( '../../public/rock-paper-coin-0034.jpg' );
			background-position: center center;
			background-size: cover;
		}

		.reset-password__form-wrapper {
			background-color: #fff;
			width: 100vw;
			padding: 0 1.5rem;
			margin: auto;

			@media ( min-width: #{$desktop} ) {
				width: 39.563rem;
				padding: 1.25rem 8rem;
				border-radius: 5px;
				box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
				border: solid 1px rgba( 207, 206, 207, 0.37 );
			}

			.reset-password__header {
				text-align: left;

				@media ( min-width: #{$desktop} ) {
					text-align: center;
				}
			}

			.reset-password__subtext {
				margin-top: 0.5rem;
				text-align: left;

				@media ( min-width: #{$desktop} ) {
					text-align: center;
				}
			}

			.reset-password__input {
				&--email {
					margin-top: 1.875rem;
				}

				&--password,
				&--re-password {
					margin-top: 0.875rem;
				}
			}

			#reset-password__button--submit {
				margin: 2.5rem auto 0 auto;
			}
		}

		#footer {
			display: none;

			@media ( min-width: #{$desktop} ) {
				display: flex;
			}
		}
	}
}
