@import '../../styles/variables';

.container {
	display: inline-box;
	display: inline-flexbox;
	display: inline-flex;
	flex-direction: column;
	position: relative;
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
	vertical-align: top;

	&.fullWidth {
		width: 100%;
	}

	:global( .MuiOutlinedInput-root ) {
		padding-bottom: 2.5rem;
	}

	:global( .MuiFormControl-root ) {
		margin-bottom: -1.25rem;
	}

	&.usingBubbleMenu,
	&.disabled {
		:global( .MuiOutlinedInput-root ) {
			padding-bottom: 16.5px;
		}

		:global( .MuiFormControl-root ) {
			margin-bottom: 0;
		}
	}

	.muiTextArea textarea {
		color: transparent;
		-webkit-text-fill-color: transparent;
	}

	.tipTap,
	.textarea,
	:global( .tiptap ) {
		position: absolute;
		@media ( min-width: $desktop ) {
			top: 0;
		}

		left: 0;
		right: 0;
		max-width: 100%;
	}

	:global( .tiptap ) {
		color: $darkBlue;
		font-size: 1.125rem;
		line-height: 1.5rem;
		padding: 1.09rem 0.875rem;
		outline: 0;

		&[ contenteditable='false' ] {
			color: $readableGray;
		}

		// we're inserting paragraphs, but we want to display them like newlines
		p {
			margin: 0.125rem 0;
		}

		p:first-child {
			margin-top: 0;
		}

		p:last-child {
			margin-top: 0;
		}

		a {
			color: $pink;
		}
	}

	// For the placeholder
	// https://tiptap.dev/docs/editor/extensions/functionality/placeholder#additional-setup
	:global( .tiptap p.is-editor-empty:first-child::before ) {
		color: #adb5bd;
		content: attr( data-placeholder );
		float: left;
		height: 0;
		pointer-events: none;
	}
}
