#page-user {
	.table__header__column.header-column--event-date {
		@media ( min-width: #{$desktop} ) {
			flex: 1;
		}
	}

	.role-widget {
		position: relative;
		margin-bottom: 2rem;
		display: inline-block;

		.role-selector {
			display: flex;
			align-items: center;

			&__self {
				color: rgb( 243, 142, 125 );
			}
		}

		svg {
			margin-left: 0.25rem;
			width: 0.5rem;
			height: 0.65rem;
			fill: $darkGray;
		}
	}

	.role-dropdown {
		position: absolute;
		right: auto;
	}

	.contacts-table-header {
		margin-bottom: 2rem;
	}

	#contacts-table .table__row__item--name {
		.planner-star-icon {
			display: flex;
			align-self: center;

			svg {
				width: 1rem;
				height: 1rem;
				margin-left: 0.5rem;
				margin-right: 0.35rem;
				fill: #{$pink};
			}
		}
	}

	@media ( min-width: #{$desktop} ) {
		#contacts-table .table-header__column--name {
			margin-left: calc( 64px + 1.5rem );
			margin-right: -1.5rem;
		}

		#contacts-table .table-header__column--assigned-to {
			margin-left: 3rem;
			margin-right: -3rem;
		}

		/* add a little breathing room to prevent the name form running up against the date */
		#contacts-table .table__row__item--name {
			padding-right: 1rem;
		}
	}

	.planner-dropdown {
		position: absolute;
		left: auto;
		right: 0;

		@media ( min-width: #{$desktop} ) {
			right: auto;
			left: 0;
		}

		svg {
			position: absolute;
			margin-left: 0.25rem;
			width: 0.5rem;
			height: 0.65rem;
			fill: $darkGray;
		}
	}

	.table__row__item--arrow {
		svg {
			top: 0.5rem;
			position: absolute;
			margin-left: 0.25rem;
			width: 0.5rem;
			height: 0.65rem;
			fill: $darkGray;
		}
	}

	.table__row,
	.table__row__item,
	.table__row__item__buttons {
		@media ( min-width: #{$desktop} ) {
			margin-bottom: 0;
		}
	}

	.table__row__item {
		position: relative;
		overflow: hidden;

		/* the only way to work around global styles in this situation, boo... */
		&.table__row__item__member {
			overflow: visible;

			.member-dropdown {
				right: auto;
				left: 0;
			}
		}
	}

	.role {
		margin: 0 5.063rem 0 0;
		font-size: 0.875rem;
		color: #b2b2b2;
	}
}

#page-user-modal {
	.prompt-container__p {
		padding: 0;
		text-align: left;
	}

	.document-modal {
		&--button {
			margin: 2.5rem auto 0;
		}
	}
}
