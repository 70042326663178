#tooltip {
	box-shadow: 0 5px 10px 0 rgba( 74, 74, 74, 0.25 );
	opacity: 1;
	padding: 1.25rem;
	max-width: 21rem;
}

.tooltip-container {
	display: flex;
	flex-direction: column;

	.permission-item {
		display: flex;
		flex-direction: row;

		.icon-container {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.icon--check {
				width: 2rem;

				svg {
					width: 1.7rem;
					height: 1.7rem;
					fill: $green;
				}
			}

			.icon--cross {
				width: 2rem;

				svg {
					width: 1.3rem;
					height: 1.3rem;
					margin-top: 0.2rem;
					margin-left: 0.2rem;
				}
			}
		}
	}

	.invite-tooltip-container {
		display: flex;

		.pending-invites-list {
			padding-inline-start: 40px;
		}
	}
}
