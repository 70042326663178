#pay-to-modal {
	&.modal {
		@media ( min-width: #{$desktop} ) {
			width: 43rem;
		}
	}

	h3 {
		width: 100%;
		margin-top: -1.25rem;
		margin-bottom: 1rem;
		text-align: left;
		font-size: 2.438rem;
	}

	h3 + p {
		text-align: left;
		margin-bottom: 2.375rem;
	}

	button {
		margin: 0 auto;
	}
}
