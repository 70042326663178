@import '../../styles/variables';

.hidden {
	display: none;
}

.input {
	margin-bottom: 1.5rem;
}

.twoColumnDesktop {
	.input:last-child {
		margin-bottom: 0;
	}
	@media ( min-width: $desktop ) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;

		.input {
			margin-bottom: 0;
		}
	}
}
