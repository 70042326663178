#page-vendors {
	&.vendor-index {
		.search-section {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			min-height: 5rem;
			margin-bottom: 1.875rem;
		}

		.vendor-index__section {
			.vendor-index__h2 {
				align-self: flex-start;
			}

			position: relative;
			margin-top: 1.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__desktop-button {
				display: none;

				@media ( min-width: #{$desktop} ) {
					display: block;
				}
			}

			&__mobile-button {
				@media ( min-width: #{$desktop} ) {
					display: none;
				}
			}

			&__mobile-menu {
				top: 1.5rem;
				right: 1.5rem;
			}

			&--search {
				&__widget {
					width: 100%;
				}
			}

			&--table {
				margin-bottom: 3.75rem;

				@media ( min-width: #{$desktop} ) {
					margin-bottom: 7.125rem;
				}
			}
		}
	}
}
