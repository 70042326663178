@import '../../styles/variables';

.container .paper {
	@media ( min-width: $desktop ) {
		max-width: 52rem;
	}
}

.centered {
	display: grid;
	justify-content: center;
	align-content: center;
}

.hugeTitle {
	font-size: 2.125rem;
	font-weight: 400;
}

.button {
	text-transform: uppercase;
	margin-top: auto;
}

.item {
	border: solid 1px $mediumGray;
	border-radius: 0.5rem;
	text-align: center;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.two,
.three {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	@media ( min-width: $desktop ) {
		margin-top: 2rem;
		flex-direction: row;
	}
}

.three .item {
	@media ( min-width: $desktop ) {
		min-width: calc( 33% - (2rem / 3) );
	}
}

.two .item {
	@media ( min-width: $desktop ) {
		width: 50%;
	}
}
