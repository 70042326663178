#page-company-folders,
#page-client-folders,
#page-client-folder {
	.folder-header,
	.clients-folder-index__header {
		width: 100%;
		min-height: 4.0625rem;
		margin-bottom: 1rem;

		// to avoid the double-underline that can appear when we wrap a link around a h2
		&:hover {
			text-decoration: none;
		}

		@media ( min-width: #{$desktop} ) {
			margin-bottom: 2rem;
		}
	}

	.breadcrumbs,
	.clients-folder-index__search,
	.filters-row {
		min-height: 2.625rem;
	}
}
