@import '../../../../styles/variables';

.container {
	overflow: hidden;
	border: solid 1px rgba( 0, 0, 0, 0.23 );
	border-radius: 0.25rem;
	padding: 1rem;
	background: $white;
	position: relative;
	user-select: none;

	.dragHandleContainer {
		position: relative;
		justify-self: center;
		margin-right: 0.5rem;
		padding: 0.5rem;
		border-radius: 2rem;
		cursor: grab;
		display: none;
		border: none;
		outline: none;
		max-width: 2.5rem;

		&:hover,
		&:focus {
			background: rgba( 0, 0, 0, 0.04 );
		}
		@media ( min-width: $desktop ) {
			display: grid;
		}
	}

	.text {
		margin-right: 2.5rem;
	}
}

.modal {
	padding-top: 0.5rem;
	display: grid;
	gap: 1.5rem;
	@media ( min-width: $desktop ) {
		min-width: 28.5rem;
	}
}

.buttons {
	display: grid;
	grid-template-columns: 1fr min-content;
	align-content: space-between;
	justify-items: end;
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
	fill: $darkBlue;

	&.addButton {
		fill: $muiGreen;
	}
}

.both {
	grid-column: span 2;
}

.left {
	grid-column: 1;
}

.right {
	grid-column: 2;
}
