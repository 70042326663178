.container {
	grid-column: span 2;
	padding: 0;
}

.list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	width: 100%;
}

.pairedItem {
	padding-left: 4rem;
}
