@import '../../styles/variables';

.container {
	position: relative;
	line-height: 0.75;
}

.background {
	color: $mediumGray;
}

.foreground {
	color: $green;
	animation-duration: 550ms;
	position: absolute;
	left: 0;

	& :global( .MuiCircularProgress-circle ) {
		stroke-linecap: round;
	}
}
