.flagContainer {
	margin-right: 2px;
	margin-left: -8px;
}

.flagSelect {
	width: max-content;

	// Remove default outline (display only on focus)
	fieldset {
		display: none;
	}

	&.Mui-focused:has( div[ aria-expanded='false' ] ) {
		fieldset {
			display: block;
		}
	}

	// Update default spacing
	.MuiSelect-select {
		padding: 8px;
		padding-right: 24px !important;
	}

	svg {
		right: 0;
	}
}

.flag {
	display: flex;
}

.flagImage {
	margin-right: 8px;
}

.countryName {
	margin-right: 8px;
}
