.container {
	border: none;
	padding: 0;
	background: transparent;
	display: inline-flex;

	:focus {
		text-decoration: underline;
	}
}
