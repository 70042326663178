@import '../../styles/variables';

.container {
	max-width: 45rem;

	/* Using a "not-desktop" rule here instead of mobile-first and desktop-override because we
	have standardized behavior on desktop but not on phone for page content in relation to the
	fixed header. So with this approach the "standard desktop" margin is used for desktop, and
	on phones we have different, one-off behavior. Maybe someday we can standardize non-desktop
	content like we have done for desktop. */
	@media ( max-width: $desktop ) {
		padding-top: 2rem;
	}
}
