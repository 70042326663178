@import '../../../styles/variables';

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.text {
	margin: 0 auto;
}

.textArea {
	width: 35rem;
	margin: 3rem auto 0 auto;
	min-height: 100%;

	> {
		div:first-child {
			min-height: 100%;

			> {
				div {
					min-height: 100%;

					:global( .MuiInputBase-multiline ) {
						min-height: calc( 100% + 2px );
					}
				}
			}
		}
	}
}

.error {
	color: $red;
	margin: 1rem auto;
}
