@import '../../../styles/variables';

.mobileToggleWrap {
	:global .MuiIconButton-root {
		width: 2.22rem;
		height: 2.22rem;
		border-radius: 0;
	}
}

.mobileOpenButton {
	:global .MuiSvgIcon-root {
		width: 1rem;
		height: 1rem;
		top: 0.2rem;
		position: relative;
	}
}

.mobileCloseButton {
	background-color: rgba( green, 0.1 );

	:global .MuiSvgIcon-root {
		width: 0.8rem;
		height: 0.8rem;
	}
}

.root {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.25rem;
	background-color: #fff;
	border-bottom: 1px solid $lightGray;
	z-index: 3;
	box-shadow: 0 2px 6px rgba( 0, 0, 0, 0.1 );

	@media ( min-width: $desktop ) {
		padding: 0 1.875rem;
	}
}

.logo {
	& > div {
		height: 4rem;
		width: 4rem;
		line-height: 0;
		position: relative;
		top: 0;
		left: -0.3rem;
	}
}

.rightControls {
	.button {
		&:global( .button--blue ) {
			width: 7rem;
		}
	}
}
