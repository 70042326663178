#page-signup-choose-user-type {
	margin: 3rem auto;

	@media ( min-width: #{$desktop} ) {
		margin-top: 0;
		padding-top: 3rem;
	}

	.user-type {
		&__button-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 2rem;

			@media ( min-width: #{$desktop} ) {
				flex-direction: row;
			}

			.user-type__selection-button {
				margin-bottom: 1rem;

				@media ( min-width: #{$desktop} ) {
					margin: 0;
				}
			}
		}

		&__vertical-rule {
			display: none;
			height: 18rem;
			margin: 0 4.25rem;
			border-left: 1px solid #eeedf0;

			@media ( min-width: #{$desktop} ) {
				display: block;
			}
		}

		&__selection-button {
			width: 13.5rem;
			height: 13.5rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background-color: white;
			border: 1px solid rgba( 207, 206, 207, 0.37 );
			border-radius: 5px;
			box-shadow: 30px 30px 60px 0 rgba( 221, 221, 221, 0.25 );

			&:hover {
				border-color: $pink;
				cursor: pointer;
			}

			svg {
				width: 3rem;
				height: auto;
				margin-bottom: 0.5rem;
				fill: $pink;
			}

			&__p {
				max-width: 9rem;
			}
		}
	}
}
