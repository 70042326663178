#members-index {
	margin-top: 1.5rem;
	padding-bottom: 12rem;
	@media ( min-width: #{$desktop} ) {
		padding-bottom: inherit;
	}

	.table-header {
		justify-content: flex-start;

		&__column {
			@media ( min-width: #{$desktop} ) {
				flex-basis: auto;
				flex-grow: unset;
			}

			&--role {
				pointer-events: none;

				svg {
					display: none;
				}
			}
		}
	}

	.table-header__column,
	.table__row__item {
		&--name {
			@media ( min-width: #{$desktop} ) {
				width: 40%;
				margin-bottom: 0;
				text-align: center;
			}

			p,
			a {
				text-align: left;
			}
		}

		&--clients {
			@media ( min-width: #{$desktop} ) {
				width: 20%;
				margin-bottom: 0;
			}
		}

		&--role {
			@media ( min-width: #{$desktop} ) {
				width: auto;
				text-align: left;
				margin-bottom: 0;
				margin-left: 10px;
			}
		}
	}

	.table-header__column--name {
		@media ( min-width: #{$desktop} ) {
			margin-left: 5.5rem;
			margin-right: -5.5rem;
			text-align: left;
		}
	}

	.table__row {
		display: flex;

		@media ( min-width: #{$desktop} ) {
			flex-wrap: wrap;
		}

		#owner {
			color: #203a60;

			&:hover {
				cursor: default;
				text-decoration: none;
			}
		}

		#self {
			&:hover {
				cursor: default;
				text-decoration: none;
			}
		}

		&__item--role,
		&__item--clients {
			a {
				display: block;
			}

			p,
			a {
				position: relative;
				margin-bottom: 0.5rem;
				text-align: right;
				@media ( min-width: #{$desktop} ) {
					text-align: left;
					margin-bottom: inherit;
				}

				&::before {
					content: attr( data-label );
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY( -50% );
					text-transform: uppercase;
					font-size: 0.875rem;
					color: $gray;

					@media ( min-width: #{$desktop} ) {
						display: none;
					}
				}
			}

			@media ( min-width: #{$desktop} ) {
				display: flex;
				position: relative;
				align-items: center;
			}

			svg {
				position: relative;
				margin-left: 0.25rem;
				width: 0.5rem;
				height: 0.65rem;
				fill: $darkGray;
			}

			&-dropdown {
				@media ( min-width: #{$desktop} ) {
					position: absolute;
					left: auto;
					top: 100%;
				}

				position: absolute;
				left: auto;
				top: 70%;
				align-items: flex-start;
				color: rgb( 243, 142, 125 );

				&:hover {
					cursor: pointer;
				}

				a {
					&::before {
						display: none;
					}

					&:last-of-type {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}

	.table__row__item__buttons {
		overflow: visible;

		div {
			cursor: pointer;
			width: auto;
			display: inline-block;
		}

		@media ( min-width: #{$desktop} ) {
			width: calc( 65% - 10rem );
			margin-left: auto;
			flex: 1;
			margin-bottom: 0;
		}

		.dropdown {
			display: flex;
		}
	}

	.members {
		&__invite {
			.button {
				&#invite-btn {
					display: none;

					@media ( min-width: #{$desktop} ) {
						display: flex;
					}
				}
			}

			.members__header {
				display: flex;
				flex-direction: column;
				align-items: left;

				h2 {
					margin-bottom: 0.5rem;
				}
			}
		}

		&__mobile-menu {
			&-container {
				position: relative;

				@media ( min-width: #{$desktop} ) {
					display: none;
				}
			}

			top: 1.5rem;
			right: 1.5rem;
		}
	}
}
