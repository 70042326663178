#_404_page,
#_error_page {
	margin: 0 auto;
	max-width: 80%;

	@media ( min-width: #{$mediumScreen} ) {
		max-width: 21.875rem;
		margin: 5rem auto;
	}

	h1 {
		color: $darkBlue;
		font-size: 3rem;
		margin: 0 0 1rem 0;
		font-weight: bold;
		text-align: center;

		@media ( min-width: #{$mediumScreen} ) {
			font-size: 7rem;
			margin: -1.5rem 0 2rem 0;
		}
	}

	img:first-child {
		height: 7.188rem;
		max-width: 12rem;

		@media ( min-width: #{$mediumScreen} ) {
			height: 10.313rem;
		}
	}

	img:last-child {
		pointer-events: none;
		position: absolute;
		width: 15rem;
		top: 7%;
		left: 42%;

		@media ( min-width: #{$mediumScreen} ) {
			width: 21.875rem;
			top: 15%;
			right: 15%;
		}
	}

	p {
		line-height: 1.5;
		font-size: 1.6rem;
		text-align: center;
		margin: 0.5rem;

		@media ( min-width: #{$mediumScreen} ) {
			color: $darkBlue;
			font-size: 1.2rem;
		}

		a {
			font-size: 1.8rem;

			@media ( min-width: #{$mediumScreen} ) {
				font-size: 1.2rem;
			}
		}
	}
}
