#page-org-member-signup {
	.org-member-sign-up {
		&__h1 {
			margin-bottom: 2.5rem;
		}

		&__row {
			margin-top: 0.875rem;
			display: flex;
			flex-direction: column;

			@media ( min-width: #{$desktop} ) {
				flex-direction: row;
			}

			> div + div {
				margin-top: 0.875rem;

				@media ( min-width: #{$desktop} ) {
					margin-top: 0;
					margin-left: 1.5rem;
				}
			}

			&--phone {
				.input-label {
					color: #000;
					opacity: 0.5;
					font-size: 1.125rem;
					margin-bottom: 0.5rem;
				}

				.MuiOutlinedInput-notchedOutline {
					border-color: rgba( 207, 206, 207, 0.37 );
					border-width: 1px;
					border-radius: 5px;
				}

				input:hover + .MuiOutlinedInput-notchedOutline {
					border-color: rgb( 243, 142, 125 );
				}

				input:focus + .MuiOutlinedInput-notchedOutline {
					border-color: rgb( 4, 149, 95 );
				}
				@media ( min-width: #{$desktop} ) {
					.input {
						width: calc( 50% - 0.75rem );
					}
				}
			}
		}

		&__checkbox {
			justify-content: flex-start;
			margin: 2rem 0 2rem 0;
		}
	}
}
