@import '../../styles/variables';

.root {
	padding: 2.25rem 0;
	border-radius: 5px;
	overflow-x: hidden;

	@media ( min-width: $desktop ) {
		padding: 2.25rem 3rem;
		max-width: 43rem;
	}
}

.closeButton {
	position: absolute;
	top: 3rem;
	right: 1rem;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		fill: $darkBlue;
	}

	@media ( min-width: $desktop ) {
		right: 4rem;
	}
}

.rootTextAlignCenter :global( .MuiPaper-root ) {
	text-align: center;
}

.title {
	font-size: 1.6875rem;
}

.description {
	/* add some padding to match the MuiPaper-root in here, to better align with the X,
	and to handle when there is only description and buttons but no content */
	padding: 0 1.5rem;
	line-height: 1.75rem;
}

.closeButton + .title {
	/* to allow for the X to not run into the title when the close button exists */
	margin-right: 2rem;
}

.controls {
	margin: 1rem -0.5rem;
	display: flex;
	justify-content: center;

	button {
		margin: 0 0.5rem;
	}

	// split button contents should get no such margin
	:global( .MuiButtonGroup-root ) > button {
		margin: 0;
	}
}
