#edit-contract {
	section,
	.container--with-border {
		@media ( max-width: #{$tablet} ) {
			display: none;
		}
	}

	#edit-contract-disabled {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 2rem 0;

		@media ( min-width: #{$tablet} ) {
			display: none;
		}

		> p {
			width: 100%;
			margin-bottom: 1rem;
		}
	}

	#edit-contract-intro {
		width: 100%;
		position: relative;
		padding-left: 0;
		padding-right: 0;

		#reset-contract-btn {
			width: 1.5rem;
			height: 1.5rem;
			position: absolute;
			top: 2rem;
			right: 0;
			cursor: pointer;

			svg {
				* {
					fill: $darkBlue;
				}
			}
		}

		h1 {
			width: calc( 100% - 2rem );
			display: inline-block;
			margin-bottom: 1rem;
			font-size: 2rem;
			line-height: 1;
		}

		h1 + p {
			margin-bottom: 1.5rem;
		}
	}
}

#document-modal {
	opacity: 0;
	animation: fadeIn 500ms linear 200ms forwards;

	@media ( min-width: #{$tablet} ) {
		min-width: 39.625rem;
	}

	&.modal {
		overflow-y: visible;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	input[ type='file' ],
	input[ type='file' ]::-webkit-file-upload-button {
		/* chromes and blink button */
		cursor: pointer;
	}

	h3 {
		width: 100%;
		margin-top: -1rem;
		margin-bottom: 1rem;
		font-size: 1.688rem;
	}

	h3 + p {
		text-align: left;
		margin-bottom: 2.5rem;
	}

	.document-modal {
		overflow-y: auto;

		&__options {
			width: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;

			@media ( min-width: #{$tablet} ) {
				flex-direction: row;
				justify-content: center;
				align-items: stretch;
			}
		}

		&__new-contract {
			position: relative;
			margin-bottom: 1.873rem;
			cursor: pointer;

			@media ( min-width: #{$tablet} ) {
				margin-bottom: 0;
			}

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}

		&__new-contract,
		&__contract-template {
			width: 13.375rem;
			padding: 1.625rem 1rem;
			text-align: center;
			background-color: #fff;
			border-radius: 5px;
			box-shadow: 30px 30px 60px 0 rgba( 221, 221, 221, 0.25 );
			border: solid 1px rgba( 207, 206, 207, 0.37 );

			.select,
			.input-select__control,
			.input-select__menu {
				width: 100% !important;
			}

			.select .input-select-container .input-select__control .input-select__value-container {
				padding-top: 0;
				padding-bottom: 0;
			}

			svg {
				width: 3rem;
				height: auto;
				margin-bottom: 0.5rem;

				* {
					fill: $pink;
				}
			}

			p {
				margin-bottom: 0.5rem;
				font-size: 1.375rem;
			}
		}

		&__contract-template {
			@media ( min-width: #{$tablet} ) {
				margin-left: 3.875rem;
			}

			&:hover {
				cursor: pointer;
				border-color: $pink;
				transition: border-color 400ms;
			}

			.select .input-select-container .input-select__control .input-select__dropdown-indicator {
				width: 1.5rem;
				height: 1.5rem;
				display: flex;
				padding: 0;
				margin: 0;

				* {
					margin: 0;
					fill: $darkBlue;
				}
			}
		}
	}
}

#page-contract-edit-submit-bar {
	width: 100%;
	height: 5.625rem;
	position: sticky;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	z-index: 3;

	&::after {
		content: none;
	}

	@media ( min-width: #{$desktop} ) {
		justify-content: flex-end;
		padding: 0 3rem;
		border-top: 1px solid $lightGray;
		box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
	}

	button + button {
		margin-left: 1rem;
	}
}

#invoices-modal {
	@media ( min-width: #{$tablet} ) {
		min-width: 39.625rem;
	}

	h3 {
		width: 100%;
		margin-top: -0.5rem;
		font-size: 1.688rem;
	}

	.invoices-modal {
		&__btn-group {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 2rem;

			button + button {
				margin-left: 1rem;
			}
		}
	}
}

#modal--void-contract {
	display: flex;
	flex-direction: column;

	@media ( min-width: #{$desktop} ) {
		min-width: 37.5rem;
	}

	h3 {
		margin-top: 3rem;

		@media ( min-width: #{$desktop} ) {
			margin-top: 0;
		}
	}

	.void-modal__btn-group {
		margin-top: 1rem;
		display: flex;
		flex-direction: column;

		@media ( min-width: #{$desktop} ) {
			flex-direction: row;
		}

		.button {
			margin: 1.5rem auto 0.5rem;

			@media ( min-width: #{$desktop} ) {
				margin: auto;
			}
		}
	}
}
