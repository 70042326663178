@import '../../styles/variables';

.menuItem {
	padding-top: 0.77rem;
	padding-bottom: 0.77rem;

	:global .MuiTypography-root {
		letter-spacing: 0;
		font-size: 1.125rem;
	}

	:global .MuiSvgIcon-root {
		color: $darkBlue;
	}

	:global .MuiAvatar-root {
		width: 1.25em;
		height: 1.25em;
	}
}

.menuItemActive {
	background-color: rgba( $green, 0.1 );

	&::before {
		content: '';
		width: 4px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: $green;
	}
}

.linkWrap {
	text-decoration: none;
}
