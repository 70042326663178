#page-org-signup {
	.org-sign-up {
		&__h1 {
			margin-bottom: 2.5rem;
		}

		&__row {
			margin-top: 0.875rem;
			display: flex;
			flex-direction: column;

			@media ( min-width: #{$desktop} ) {
				flex-direction: row;
			}

			> div + div {
				margin-top: 0.875rem;

				@media ( min-width: #{$desktop} ) {
					margin-top: 0;
					margin-left: 1.5rem;
				}
			}

			&--business-type {
				@media ( min-width: #{$desktop} ) {
					.select {
						width: calc( 50% - 0.75rem );
					}
				}
			}
		}

		&__checkbox {
			max-width: 30rem;
			margin: 2.5rem auto 0 auto;

			.checkbox-container {
				margin-right: 1rem;
			}
		}

		&__exposure-row {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin: 1.5rem 0;

			@media ( min-width: #{$desktop} ) {
				align-items: flex-end;
				flex-direction: row;
			}

			&--input {
				max-width: 21.5rem;
			}
		}

		&__coupon-row {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin: 1.5rem 0;

			@media ( min-width: #{$desktop} ) {
				align-items: flex-end;
				flex-direction: row;
			}

			&--input {
				max-width: 21.5rem;
			}

			&--verify {
				margin-top: 1rem;

				@media ( min-width: #{$desktop} ) {
					margin: 0 0 0.5rem 1rem;
				}
			}
		}

		&__valid-coupon-row {
			display: flex;
			margin-top: 1rem;

			p {
				margin-left: 0.5rem;
			}

			svg {
				width: 1.5rem;
				height: 1.5rem;
				fill: $pink;
			}
		}

		&__select {
			margin: 1.5rem 0 0.5rem 0;
		}
	}
}
