#page-contract-templates {
	&.contract-templates-index {
		.contract-templates-index {
			&__section {
				position: relative;
				margin-top: 1.5rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				&__desktop-button {
					display: none;

					@media ( min-width: #{$desktop} ) {
						display: block;
					}
				}

				&__mobile-button {
					@media ( min-width: #{$desktop} ) {
						display: none;
					}
				}

				&__mobile-menu {
					top: 1.5rem;
					right: 1.5rem;
				}

				&--table {
					margin-top: 0;
					margin-bottom: 3.75rem;

					@media ( min-width: #{$desktop} ) {
						margin-top: 1.5rem;
						margin-bottom: 7.125rem;
					}

					.table__header {
						height: 3.5rem;
						align-items: center;
					}

					.table__row {
						&__item__p {
							margin-bottom: 0;
						}
					}

					@media ( min-width: #{$desktop} ) {
						.table__row {
							margin-bottom: 0;
							padding: 1.688rem 0;
							height: 5rem;

							a,
							p {
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								max-width: 90%;
							}
						}

						.table__row__item,
						.table__row__item__buttons {
							margin-bottom: 0;
							flex: 1;
						}
					}
				}
			}
		}
	}
}

#page-contract-templagtes-modal {
	p {
		padding-right: 4rem;
	}
}
