#page-contract-template {
	section,
	.container--with-border {
		@media ( max-width: #{$tablet} ) {
			display: none;
		}
	}

	#edit-contract-disabled {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 2rem 0;

		@media ( min-width: #{$tablet} ) {
			display: none;
		}

		> p {
			width: 100%;
			margin-bottom: 1rem;
		}
	}

	#edit-contract-intro {
		width: 100%;
		position: relative;
		display: none;

		@media ( min-width: #{$tablet} ) {
			display: block;
		}

		#reset-contract-btn {
			width: 1.5rem;
			height: 1.5rem;
			position: absolute;
			top: 2rem;
			right: 1.5rem;
			cursor: pointer;

			svg {
				* {
					fill: $darkBlue;
				}
			}
		}

		h1 {
			font-size: 2rem;
		}

		h1 + p {
			margin-bottom: 1.5rem;
		}
	}
}

#contract-template-bar {
	width: 100%;
	height: 5.625rem;
	position: sticky;
	left: 0;
	bottom: 0;
	display: none;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	background-color: #fff;
	z-index: 3;

	@media ( min-width: #{$tablet} ) {
		display: flex;
		justify-content: flex-end;
		padding: 0 3rem;
		border-top: 1px solid $lightGray;
		box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
	}

	&::after {
		content: none;
	}

	#delete-contract-template-btn {
		max-width: 5rem;

		p {
			display: none;
		}

		svg {
			margin-right: 0;
		}

		@media ( min-width: #{$tablet} ) {
			max-width: none;

			p {
				display: block;
			}

			svg {
				margin-right: 0.5rem;
			}
		}
	}
}

#delete-contract-template-modal,
#create-contract-template-modal {
	&.modal {
		@media ( min-width: #{$desktop} ) {
			width: 43rem;
		}
	}

	h3 {
		width: 100%;
		margin-top: -1rem;
		margin-bottom: 1rem;
		text-align: left;
		font-size: 1.688rem;
	}

	h3 + p {
		text-align: left;
		margin-bottom: 1.873rem;
	}

	.modal__body {
		display: flex;
		justify-content: center;
		align-items: center;

		button + button {
			margin-left: 1rem;
		}
	}
}
