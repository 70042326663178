@import '../../styles/variables';

.container {
	.svg {
		position: relative;
		width: 1.5rem;
		height: 1.5rem;
	}

	/* Using a radial gradient here because the circular path on the SVG is actually
	inset from the svg edges, so we need the grey part to not reach to the end, just
	for the circular path */
	/* stylelint-disable declaration-colon-space-after */
	&:global( .Mui-disabled ):not( :global( .Mui-checked ) ) svg {
		background:
 radial-gradient( circle, rgba( 229, 229, 229, 1 ) 50%, rgba( 255, 255, 255, 1 ) 50%, rgba( 255, 255, 255, 1 ) 100% );
	}
	/* stylelint-enable declaration-colon-space-after */

	&:global( .Mui-checked ) svg {
		fill: $green;
		color: $green;
	}

	&:global( .Mui-disabled ) svg {
		fill: $readableGray;
		color: $readableGray;
	}
}
