@import '../../../../styles/variables';

.container {
	position: relative;
}

.paper {
	padding: 1rem;
	max-width: 20rem;
}

.icon {
	width: 2rem;
	height: 2rem;
	fill: $darkBlue;
}

.dismiss {
	display: flex;
	flex-direction: row;
	align-items: center;
}
