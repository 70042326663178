.root {
	display: flex;
	flex-direction: column;
	border-right: 1px solid #e6e6e6;
	height: 100%;
	padding-top: 1rem;
}

.contractTemplateIcon {
	width: 1.65rem;
	height: 1.65rem;
	transform: translateX( -0.21rem );
}
