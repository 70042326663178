main,
.element-main {
	max-width: 71.75rem;

	// 4 rem is the height of the header
	min-height: calc( 100vh - 4rem );
	margin: 0 auto 6rem auto;
	padding: 0 1.5rem;

	&::before,
	&::after {
		clear: both;
		content: '';
	}

	@media ( min-width: #{$desktop} ) {
		padding: 4rem 1.5rem 0 1.5rem;
	}
}
