.button {
	border: unset;
	display: inline;
	padding: unset;
	margin: unset;
	background: unset;

	&:hover,
	&:focus {
		background: unset;
	}
}

.loading:hover {
	cursor: wait;
}
