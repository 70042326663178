#page-client-folders {
	.clients-folder-index {
		&__search {
			height: 2.625rem;

			&-input {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				svg {
					width: 1.5rem;
					height: 1.5rem;

					* {
						fill: $green;
					}
				}

				input {
					margin-left: 0.5rem;
					appearance: none;
					outline: none;
					border: none;
					font-size: 1.125rem;

					&::placeholder {
						margin-left: 0.688rem 1.625rem 0.688rem 1rem;
						opacity: 0.2;
						font-size: 1.125rem;
						color: $darkBlue;
					}
				}

				a {
					cursor: pointer;

					svg {
						width: 1.5rem;
						height: 1.5rem;

						* {
							fill: $error;
						}
					}
				}
			}
		}

		&__empty {
			margin-top: 1rem;
		}
	}
}
