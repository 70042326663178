#page-resource {
	#resource-back-button {
		margin-top: 2rem;

		@media ( min-width: #{$desktop} ) {
			margin-top: 0;
			display: block;
		}
	}

	.resource-wrapper {
		width: 100%;
	}

	.resource-wrapper--with-border {
		position: relative;
		margin-bottom: 2.5rem;
		border: 1px solid $mediumGray;
		box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
		border-radius: 11px;

		@media ( min-width: #{$desktop} ) {
			margin-bottom: 3.5rem;
		}

		.section--header {
			flex: 1;
			display: flex;
			flex-direction: row;
			padding: 2rem 2rem 0 2rem;
			justify-content: space-between;
			flex-wrap: wrap;

			@media ( min-width: #{$desktop} ) {
				padding: 4rem 4rem 0 4rem;
			}
		}

		.f05 {
			flex: 1;

			@media ( min-width: #{$desktop} ) {
				flex: 0.45;
			}
		}

		.resource-detail__header {
			padding: 3rem 0.5rem 0 0.5rem;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			min-width: 180px;

			@media ( min-width: #{$desktop} ) {
				padding: 3rem 2rem 0 2rem;
				max-width: 51rem;
				flex-direction: column;
				min-width: 380px;
			}

			&--name {
				display: flex;
				flex-direction: column;

				@media ( min-width: #{$desktop} ) {
					flex-basis: 40%;
					margin-top: 0;
				}
			}

			input,
			input:disabled,
			input::placeholder {
				font-size: 2rem;
				font-weight: bold;
				color: $darkBlue;
				opacity: 1;
				background-color: rgba( 0, 0, 0, 0 );
			}

			.resource-sponsor-profile {
				min-width: 150px;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0.6rem 0;

				a {
					font-size: 1.5rem;
					font-weight: 600;
				}

				.sponsor-name {
					margin-left: 16px;
				}

				.sponsor-image__sponsor {
					border-width: 1px;
					border-style: solid;
					width: 3.5rem !important;
					height: 3.5rem !important;
					border-color: rgba( 0, 0, 0, 0.15 );

					@media ( min-width: #{$desktop} ) {
						width: 4rem !important;
						height: 4rem !important;
					}
				}
			}
		}

		.resource-detail__stats {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 0;
			margin-top: 20px;

			@media ( min-width: #{$desktop} ) {
				max-width: 51rem;
				padding: 1.5rem 5rem 0 0;
				margin-top: 0;
			}

			&--fileType {
				display: flex;
				flex-direction: column;

				.fileType__details {
					display: flex;
					flex-direction: row;

					svg {
						width: 1.5rem;
						height: 1.5rem;

						* {
							fill: $pink;
						}
					}

					p {
						margin-left: 0.5rem;
					}
				}
			}

			&--last-updated {
				margin-left: 0;

				@media ( min-width: #{$desktop} ) {
					margin-left: 1.5rem;
				}
			}

			&--shared-with {
				flex-basis: 100%;
				margin-top: 1.5rem;

				@media ( min-width: #{$desktop} ) {
					flex-basis: 40%;
					margin-top: 0;
				}

				.shared-with__button {
					svg {
						width: 1.5rem;
						height: 1.5rem;

						* {
							fill: $pink;
						}
					}
				}
			}

			&__shared-permissions {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.share-widget {
					width: auto;

					& + div {
						margin-left: 1rem;
						cursor: pointer;
					}
				}
			}
		}

		.resource-detail__file-viewer {
			margin-top: 3rem;
			padding: 1.5rem 2rem 1.5rem 2rem;
			border-top: 1px solid $mediumGray;
			position: relative;

			.imageSizerContainer {
				position: relative;
				width: 100%;
				min-height: 1rem;

				img {
					display: block;
					margin: auto;
					max-width: 100%;
					position: absolute;
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}

			@media ( min-width: #{$desktop} ) {
				padding: 2rem 5rem 2.5rem 5rem;
			}
		}
	}
}

#page-resource-footer {
	&.container__footer.container--no-padding {
		width: 100%;
		height: 5.5rem;
		position: sticky;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		margin: 0 0 0 -24px;
		z-index: 4;

		&::after {
			content: none;
		}

		@media ( min-width: #{$desktop} ) {
			position: fixed;
			justify-content: flex-end;
			margin: 0;
			padding: 0 3rem;
			border-top: 1px solid $lightGray;
			box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
			left: auto;
			right: 0;
			width: calc( 100% - 7.5rem );
		}

		button {
			p {
				font-size: 1rem;
			}

			svg {
				width: 1rem;
				height: 1rem;
			}
		}

		button + button {
			margin-left: 1rem;
		}

		#export-resource-btn,
		#delete-resource-btn {
			max-width: 5rem;

			p {
				display: none;
			}

			svg {
				margin-right: 0;
			}

			@media ( min-width: #{$desktop} ) {
				max-width: none;

				p {
					display: block;
				}

				svg {
					margin-right: 0.5rem;
				}
			}
		}
	}
}
