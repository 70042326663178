@import '../../../../styles/variables';

.container {
	position: sticky;
	bottom: 0;
	margin-right: auto;
	z-index: 1;

	&.freeze {
		position: absolute;
		bottom: calc( 100% - 38px - 2.2rem );
	}

	.alignment {
		margin: 1px 0 0 2px;
		overflow: hidden;
		padding: 5px 15px 0 0;
		display: flex;

		.shadow {
			display: flex;
			flex-basis: min-content;
			border-radius: 0 0.25rem 0 0.25rem;
			gap: 0.25rem;
			padding: 0.25rem;
			background: $white;
			box-shadow: $boxShadowSmall;
		}
	}
}
