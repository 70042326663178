.page-proposals {
	.proposals-index__section {
		position: relative;
		margin-top: 1.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&--search {
			&__widget {
				width: 100%;
			}
		}
	}
}
