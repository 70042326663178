@import './variables';

@keyframes backgroundTransition {
	0% {
		background-color: inherit;
	}

	25% {
		background-color: #{$lightGray};
	}

	50% {
		background-color: #{$lightGray};
	}

	100% {
		background-color: inherit;
	}
}

.pulse-lightGray {
	opacity: 1;
	animation: backgroundTransition $pulseAnimationDuration ease-in 0s forwards;
}

@mixin shake-x {
	@keyframes shakeX {
		0% {
			transform: translateX( 0 );
		}

		100% {
			transform: translate3d( 0, 0, 0 );
		}

		6.5% {
			transform: translateX( -6px ) rotateY( -9deg );
		}

		10%,
		30%,
		70%,
		90% {
			transform: translate3d( -10px, 0, 0 );
		}

		20%,
		40%,
		60%,
		80% {
			transform: translate3d( 10px, 0, 0 );
		}

		18.5% {
			transform: translateX( 5px ) rotateY( 7deg );
		}

		31.5% {
			transform: translateX( -3px ) rotateY( -5deg );
		}

		43.5% {
			transform: translateX( 2px ) rotateY( 3deg );
		}

		50% {
			transform: translateX( 0 );
		}
	}

	animation: shakeX 0.75s linear 0s 2;
}
