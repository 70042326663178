@import '../../../styles/variables';

.root {
	width: 100vw;
	position: fixed;
	top: $headerHeight;
	left: 0;
	background-color: white;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.rootProfileIsOpen {
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba( black, 0.1 );
		z-index: 2;
	}
}

.menuWrap {
	height: calc( 100% - $headerHeight );
	overflow: auto;
	position: relative;
}

.profileWrap {
	z-index: 3;
	position: relative;
	background-color: white;

	:global .MuiPaper-root {
		margin: 0;
	}
}
