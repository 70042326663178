@import '../../styles/variables';

/* Nesting mui-tooltip-paper inside of container allows the styles here to override the MUI styles */
.container {
	.mui-tooltip-paper {
		padding: 1rem 2rem;
		margin-top: -2rem;
		pointer-events: none;
		white-space: pre-wrap;
		width: max-content;
	}
}

.text {
	color: $darkBlue;
	font-size: 1rem;
}
