#page-contracts {
	&.contracts-index {
		.contracts-index__section {
			position: relative;
			margin-top: 1.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__desktop-button {
				display: none;

				@media ( min-width: #{$desktop} ) {
					display: block;
				}
			}

			&__mobile-button {
				@media ( min-width: #{$desktop} ) {
					display: none;
				}
			}

			&__mobile-menu {
				top: 1.5rem;
				right: 1.5rem;
			}

			&--search {
				&__widget {
					width: 100%;
				}
			}

			&--table {
				margin-top: 1.5rem;
			}

			.search-wrapper {
				width: 100%;
			}
		}
	}
}
