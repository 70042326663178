@import '../../../styles/variables';

.row {
	margin-top: 1.5rem;
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr;

	@media ( min-width: $desktop ) {
		grid-template-columns: 1fr 1fr;
	}
}
