@import './variables';

.container {
	width: calc( 100% - 3rem );
	margin: 0 auto;
	padding: 1.5rem;

	&--with-border {
		border-radius: 11px;
		border: 1px solid rgba( 207, 206, 207, 0.37 );
		box-shadow: 30px 30px 60px 0 rgba( 221, 221, 221, 0.75 );
	}

	&--no-padding {
		padding: 0;
	}

	&::after {
		content: '';
		width: 100%;
		height: auto;
		clear: both;
	}
}

.hide {
	height: 0;
	display: none !important;
	visibility: hidden !important;
	margin: 0;
	padding: 0;
	pointer-events: none;
	opacity: 0;
}

.fade {
	&-in {
		opacity: 0;
		animation: fadeIn 200ms ease-out 0s forwards;

		@keyframes fadeIn {
			from {
				opacity: 0;
			}

			to {
				opacity: 1;
			}
		}
	}

	&-out {
		opacity: 1;
		animation: fadeOut 200ms ease-out 0s forwards;

		@keyframes fadeOut {
			from {
				opacity: 1;
			}

			to {
				opacity: 0;
			}
		}
	}
}

.shake {
	&--x {
		@keyframes shakeX {
			0%,
			100% {
				transform: translate3d( 0, 0, 0 );
			}

			10%,
			30%,
			50%,
			70%,
			90% {
				transform: translate3d( -10px, 0, 0 );
			}

			20%,
			40%,
			60%,
			80% {
				transform: translate3d( 10px, 0, 0 );
			}
		}

		animation: shakeX 1s linear 0s forwards;
	}

	&--x-small {
		@keyframes shakeXSmall {
			0% {
				transform: translateX( 0 );
			}

			6.5% {
				transform: translateX( -6px ) rotateY( -9deg );
			}

			18.5% {
				transform: translateX( 5px ) rotateY( 7deg );
			}

			31.5% {
				transform: translateX( -3px ) rotateY( -5deg );
			}

			43.5% {
				transform: translateX( 2px ) rotateY( 3deg );
			}

			50% {
				transform: translateX( 0 );
			}
		}

		animation: shakeXSmall 1s ease-in-out 0s forwards;
	}

	&--y {
		@keyframes shakeY {
			0%,
			100% {
				transform: translate3d( 0, 0, 0 );
			}

			10%,
			30%,
			50%,
			70%,
			90% {
				transform: translate3d( 0, -10px, 0 );
			}

			20%,
			40%,
			60%,
			80% {
				transform: translate3d( 0, 10px, 0 );
			}
		}

		animation: shakeY 1s linear 0s forwards;
	}
}

.back-btn {
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;

	svg {
		width: 100%;
		height: auto;
		position: relative;
		transition: transform 400ms;

		* {
			fill: $success;
		}
	}

	&:hover {
		svg {
			transform: translateX( -5px );
		}
	}
}

.click-disabled {
	cursor: default;
	pointer-events: none;
}

/* utility class to affect next/image images with layout=fill without violating NextJS's eslint rules */
.unset-img {
	width: 100%;
}

.unset-img > span,
.unset-img > span > img {
	position: unset !important;
	width: unset !important;
	height: unset !important;
}

.MuiModal-root {
	overflow-y: auto;
}

#rootPortal .modal-allow-overflow {
	overflow-y: visible;
}

.legacyLink {
	font-size: 1.125rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: $pink;
	text-decoration: none;

	&:visited {
		color: $pink;
		text-decoration: none;
	}

	&:focus,
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	&:focus,
	&:active,
	&:visited,
	&:hover {
		outline: none;
	}

	&.a {
		&--dark-blue,
		&--dark-blue:visited {
			color: $darkBlue;
		}

		&--dark-blue:hover,
		&--dark-blue:focus {
			color: $pink;
		}

		&--primary {
			color: $green;
		}

		&--red {
			color: $error;
		}

		&--bold {
			font-weight: bold;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--pink {
			color: $pink;
		}

		&--underlined {
			text-decoration: underline;
		}

		&--disabled {
			pointer-events: none;
			opacity: 0.5;
		}

		&--green,
		&--green:visited {
			color: $green;
		}
	}
}
