@import '../../../styles/variables';

.form {
	margin-top: 1.5rem;
	display: grid;
	gap: 1.5rem;
}

.row {
	display: grid;
	column-gap: 1rem;
	row-gap: 1.5rem;
	@media ( min-width: $desktop ) {
		grid-template-columns: 1fr 1fr;
	}
}

.termsOfService {
	max-width: 480px;
	display: flex;
	align-items: center;
	margin: auto;

	.termsOfServiceBody {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: nowrap;

		label {
			color: $darkBlue;
			user-select: none;
		}
	}
}
