@import '../../../../styles/variables';

.button {
	border-radius: 0.25rem;
	padding: 2px;

	&.active {
		background-color: $muiLightestGreen;
	}
}

.icon {
	width: 1.25rem;
	height: 1.25rem;
	fill: $darkBlue;
}
