.container {
	display: grid;
	gap: 1rem;
	grid-template-columns: 19rem 1px auto;

	.column {
		display: grid;
		gap: 1rem;
		align-content: start;
		position: relative;
		max-width: 100%;
	}

	.list {
		position: relative;
		max-width: 100%;
		display: grid;
		gap: 0.5rem;
		align-content: start;

		&.large {
			gap: 1rem;
			grid-template-columns: 1fr 1fr;

			:global( li[ data-rfd-placeholder-context-id ] ) {
				grid-column: span 2;
			}
		}
	}
}
