#move-file-modal {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 6.25rem;

	@media ( min-width: #{$desktop} ) {
		max-width: 43rem;
		min-height: 38.125rem;
	}

	.div {
		&--modal-container {
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: hidden;
		}

		&--table-container {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: hidden;
		}

		&--loading {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1;
		}

		&--empty-folder {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1;
		}

		&--table {
			@media ( min-width: #{$desktop} ) {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				padding-top: 5px;
				overflow-y: auto;

				&__row {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 1rem 0;
					cursor: pointer;
					border-bottom: 1px solid $mediumGray;

					&:last-child {
						border-bottom-style: none;
					}

					&:hover {
						background-color: $lightGray;
					}

					p {
						margin: 0 !important;
					}

					svg {
						width: 1.5rem;
						margin: 0 8px 0 1rem;
						fill: $pink;
					}
				}
			}
		}
	}

	.move-confirmation-modal {
		&__header {
			h2 {
				margin-bottom: 0.8rem;
			}

			p {
				margin-bottom: 1.5rem;
			}
		}

		&__current-folder {
			background-color: $lightestShade;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0.5rem;

			h3 {
				margin-left: 2.5rem;
				font-size: 1.5rem;
			}

			.icon-chevron {
				width: 1.5rem;
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					width: 100%;
					height: auto;
					cursor: pointer;

					path {
						fill: $darkBlue;
					}

					rect {
						stroke: $darkBlue;
						fill: transparent !important;
					}
				}

				& + h3 {
					margin-left: 1.5rem;
				}
			}
		}

		&__folder-row,
		&__document-row {
			.div--table {
				&__row-from-folder,
				&__row-from-folder-document {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					padding: 1rem 0;
					border-top: 1px solid $mediumGray;

					&:first-of-type {
						border: none;
					}

					svg {
						width: 1.5rem;
						margin: 0 1rem;
					}

					p {
						margin: 0;
					}
				}
			}
		}

		&__folder-row {
			cursor: pointer;

			.div--table {
				&__row-from-folder {
					.left-container {
						display: flex;
						justify-content: center;
						align-items: center;

						.folder-icon {
							svg {
								display: block;
								fill: $darkBlue;
							}
						}

						.circle-check-icon {
							svg {
								display: none;
								fill: $green;
							}
						}

						.circle-check-fill-icon {
							svg {
								display: block;
								fill: $green;
							}
						}
					}

					.right-container {
						margin-left: auto;

						svg {
							fill: white;

							path {
								fill: white;
							}

							rect {
								stroke: white;
								fill: transparent;
							}
						}
					}

					&:hover {
						.left-container {
							.folder-icon {
								svg {
									display: none;
								}
							}

							.circle-check-icon {
								svg {
									display: block;
								}
							}
						}

						.right-container {
							path {
								fill: $darkGray;
							}

							rect {
								stroke: $darkGray;
							}

							&:hover {
								path {
									fill: $pink;
								}

								rect {
									stroke: $pink;
									fill: transparent;
								}
							}
						}
					}
				}
			}

			& + .move-confirmation-modal__document-row {
				.div--table__row-from-folder-document {
					border-top: 1px solid $mediumGray;
				}
			}
		}

		&__document-row {
			margin-bottom: 1rem;
			cursor: default;

			.div--table {
				&__row-from-folder-document {
					p {
						cursor: text;
						color: $darkGray;
					}

					svg {
						* {
							fill: $darkGray !important;
						}
					}
				}
			}
		}

		&__footer {
			margin-bottom: 3rem;

			.p {
				&--is-alredy {
					span {
						font-weight: 700;
					}
				}

				&--text--inline {
					display: inline;
				}
			}
		}

		&__buttons {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: auto;

			.move-modal__button--cancel {
				margin-right: 0.5rem;
			}

			.move-modal__button--confirm {
				margin-left: 0.5rem;
			}
		}
	}
}
