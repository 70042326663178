@import '../../styles/variables';

.link {
	/* most of these rules copied form the MUI button that this is mimicking.
  We want to use a link for navigation "buttons" but still have them look like buttons */

	margin: 0;
	line-height: 24px;
	letter-spacing: 0.0286em;
	font-weight: 500;
	outline: 0;
	border: 0;
	color: $white;
	min-width: 10.25rem;
	min-height: 3.5rem;
	padding: 1rem 2.5rem;
	border-radius: 4px;
	text-transform: unset;
	font-size: 1rem;
	position: relative;
	align-items: center;
	justify-content: center;
	display: inline-flex;
	cursor: pointer;
	user-select: none;
	appearance: none;
	text-decoration: none;
	transition:
		background-color 250ms cubic-bezier( 0.4, 0, 0.2, 1 ) 0ms,
		box-shadow 250ms cubic-bezier( 0.4, 0, 0.2, 1 ) 0ms,
		border-color 250ms cubic-bezier( 0.4, 0, 0.2, 1 ) 0ms,
		color 250ms cubic-bezier( 0.4, 0, 0.2, 1 ) 0ms;

	svg {
		fill: $white;
		margin-right: 8px;
		margin-left: -4px;
		height: 1.266rem;
		width: 1.266rem;
	}

	&.empty {
		min-width: 3.5rem;
		max-width: 3.5rem;
		padding-left: 0;
		padding-right: 0;

		svg {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&.disabled {
		cursor: default;
		background-color: rgba( 0, 0, 0, 0.12 );
		color: rgba( 0, 0, 0, 0.26 );

		svg {
			fill: rgba( 0, 0, 0, 0.26 );
		}

		&:hover {
			background-color: rgba( 0, 0, 0, 0.12 );
		}

		.spinner {
			height: 1.266rem;
			width: 1.266rem;

			span {
				scale: 80%;
				animation-duration: 0.75s;

				&:first-child {
					display: none;
				}
			}

			svg {
				color: rgba( 0, 0, 0, 0.26 );
				margin-left: 0;
			}
		}
	}

	&:global( .primary ) {
		background-color: $muiGreen;
		font-size: 1.125rem;
		text-transform: uppercase;

		&:hover {
			background-color: $muiDarkGreen;
		}
	}

	&.text {
		text-transform: uppercase;
		padding: 0.625rem 1rem;
		min-width: 64px;
		min-height: 0;
		line-height: 1.5rem;
		flex-shrink: 0;
		font-size: 1.125rem;

		&.textPrimary {
			color: $muiGreen;
			background-color: transparent;

			&:hover {
				background-color: $muiLightestGreen;
			}
		}

		&.textSecondary {
			color: $muiBlue;
			background-color: transparent;

			&:hover {
				background-color: $muiLightestBlue;
			}
		}
	}
}

.disabledButton:global( .Mui-disabled ) {
	pointer-events: unset;
	cursor: pointer;
}
