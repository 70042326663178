#event-date-modal {
	&.modal {
		overflow-y: visible;
	}

	@media ( min-width: #{$desktop} ) {
		min-width: 30rem;
	}

	.event-date {
		&__form {
			&__submit {
				margin: 1rem auto 0 auto;
			}
		}
	}
}
