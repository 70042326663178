@import '../../../styles/variables';

.badge :global( .MuiBadge-badge ) {
	top: 1rem;
	right: 1rem;
}

.button {
	flex-direction: column;
	height: 5rem;
	width: 7.5rem;
	padding: 0.3rem 1rem 0;
	transition: background-color 0.7s;

	&:hover {
		background-color: #f2f2f2;
	}

	:global .MuiSvgIcon-root {
		width: 1.7rem;
		height: 1.7rem;
		color: $darkBlue;
	}

	:global .MuiTypography-root {
		font-size: 1.125rem;
		padding: 0.5rem 0 0;
		text-decoration: none;
	}

	:global .MuiTouchRipple-child {
		background-color: $green;
	}
}

.buttonActive {
	background-color: rgba( $green, 0.1 );

	&::before {
		content: '';
		width: 4px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: $green;
	}

	.MuiTypography-root {
		font-weight: 700;
	}
}
