@import '../../styles/variables';

.container {
	position: relative;
}

.characterCount {
	// David asked to match the label color with respect to 5253, hence the weird color
	color: rgba( 0, 0, 0, 0.6 );
	position: absolute;
	top: -1rem;
	right: 0.5rem;
	background: $white;
	padding: 0 0.35rem;
	margin: 0.35rem 0;
	font-size: 0.875rem;

	&.characterCountDisabled {
		background: #f5f5f5;
		background: linear-gradient( 0deg, #f5f5f5 50%, #ffffff 50%, #ffffff 100% );
	}
}

.Mui-primary.Mui-focused .characterCount {
	color: $muiGreen;
}

/* This is a placeholder until we use a secondary color */
.Mui-secondary.Mui-focused .characterCount {
	color: $readableGray;
}

.errorText {
	text-align: right;
}
