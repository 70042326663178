#contract-details {
	#back-button {
		margin: 2rem 0 2rem 0;

		svg {
			* {
				fill: $gray;
				opacity: 1;
			}
		}

		@media ( min-width: #{$desktop} ) {
			margin-top: 0;
			display: block;
		}

		&:hover svg * {
			fill: #fff;
		}
	}

	.container {
		width: 100%;
		display: block;

		section {
			opacity: 0;
			animation: fadeIn 500ms linear 2s forwards;

			@keyframes fadeIn {
				from {
					opacity: 0;
				}

				to {
					opacity: 1;
				}
			}
		}

		&.container--with-border {
			position: relative;
			margin-bottom: 2.5rem;
			padding-top: 6.375rem;
			padding-bottom: 6.375rem;

			@media ( min-width: #{$desktop} ) {
				margin-bottom: 3.5rem;
			}

			.flag {
				position: absolute;
				top: 4.25rem;
				right: 0;

				&--draft {
					top: 4.75rem;
					right: -1.75rem;
				}

				&--sent {
					top: 4rem;
					right: -1rem;
				}

				&--signed {
					top: 5.25rem;
					right: -2.5rem;
				}

				&--overdue {
					right: -3.5rem;
					top: 6.5rem;
				}
			}

			section {
				max-width: 50.75rem;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&.container__footer.container--no-padding {
			width: 100%;
			height: 5.625rem;
			position: sticky;
			left: 0;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			background-color: #fff;

			&::after {
				content: none;
			}

			@media ( max-width: #{$lightGray} ) {
				margin: 0 0 0 -24px;
			}

			@media ( min-width: #{$desktop} ) {
				position: fixed;
				justify-content: flex-end;
				margin: 0;
				padding: 0 3rem;
				border-top: 1px solid $lightGray;
				box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
				display: flex;
				left: auto;
				right: 0;
				width: calc( 100% - 7.5rem );
			}

			button {
				p {
					font-size: 0.875rem;
				}

				svg {
					width: 1rem;
				}
			}

			button + button {
				margin-left: 1rem;
			}

			#sign-contract-btn.button--disabled {
				svg * {
					fill: $gray;
					opacity: 1;
				}
			}
		}

		&.container__footer--disabled {
			pointer-events: none;

			button {
				background-color: $lightGray;

				.button__content-container {
					.button-text {
						color: $black;
						opacity: 0.3;
					}

					svg {
						* {
							fill: $black;
							opacity: 0.3;
						}
					}
				}
			}
		}
	}

	#contract-view-header + #info-text {
		margin-bottom: 3.313rem;
	}

	#info-text {
		p {
			width: 100%;
		}
	}

	#contract-tos {
		margin-right: auto;
	}

	#contract-approved {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		@media ( min-width: #{$desktop} ) {
			margin-right: auto;
		}

		div {
			width: 1.5rem;
			height: 1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			background-color: $green;

			svg {
				width: calc( 100% - 0.5rem );
				height: auto;

				* {
					fill: #fff;
				}
			}
		}

		p {
			display: none;
			margin-left: 0.5rem;

			@media ( min-width: #{$desktop} ) {
				display: block;
			}
		}

		p + p {
			display: block;

			@media ( min-width: #{$desktop} ) {
				display: none;
			}
		}
	}

	#contract-approved + button {
		margin-left: 1rem;
	}

	#void-contract-btn,
	#send-contract-btn,
	#export-contract-btn {
		max-width: 5rem;

		p {
			display: none;
		}

		svg {
			margin-right: 0;
		}

		@media ( min-width: #{$desktop} ) {
			max-width: none;

			p {
				display: block;
			}

			svg {
				margin-right: 0.5rem;
			}
		}
	}

	.p {
		text-align: center;

		&--oops {
			font-size: 4rem;
		}
	}

	img {
		width: 100%;
		display: block;
		margin: 0 auto;
	}

	#resend-contract-btn {
		margin: 1rem auto 0;
	}
}

#pay-invoice-modal {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: row-reverse;

	.container {
		width: 100%;
		max-width: 600px;
		margin: 0 auto;

		@media ( min-width: #{$desktop} ) {
			min-width: 26rem;
		}

		h1,
		p {
			width: 100%;
		}

		h1 {
			margin-bottom: 2.5rem;
			font-size: 2rem;
			font-weight: bold;
			line-height: 1.22;
		}

		h1 + p {
			margin-bottom: 1rem;
		}

		#invoice-header {
			display: none;

			@media ( min-width: #{$desktop} ) {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin: 1rem 0;
			}

			p {
				&:last-of-type {
					text-align: right;
				}
			}

			p + p {
				margin-left: 1rem;
			}
		}

		#invoice-header,
		.invoice {
			width: 100%;

			p,
			a {
				width: 100%;

				@media ( min-width: #{$desktop} ) {
					width: calc( 100% / 3 );
				}
			}
		}

		#invoices {
			width: 100%;

			@media ( min-width: #{$desktop} ) {
				max-height: 18.75rem;
				overflow-y: auto;
			}

			.invoice {
				margin-bottom: 0.5rem;
				padding-bottom: 0.5rem;
				border-bottom: 1px solid $lightGray;

				&:last-of-type {
					border: none;
				}

				@media ( min-width: #{$desktop} ) {
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}

				p {
					position: relative;

					&::before {
						content: attr( data-label );
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY( -50% );
						font-size: 0.875rem;
						text-transform: uppercase;
						color: $gray;

						@media ( min-width: #{$desktop} ) {
							display: none;
						}
					}
				}

				p + p,
				p + button {
					@media ( min-width: #{$desktop} ) {
						margin-left: 1rem;
					}
				}

				a + p,
				p + p {
					text-align: right;
					@media ( min-width: #{$desktop} ) {
						text-align: left;
					}
				}

				a {
					@media ( min-width: #{$desktop} ) {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				button {
					margin-left: auto;
				}
			}
		}
	}
}
