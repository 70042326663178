@import '../../../styles/variables';

.form {
	margin-top: 1rem;
	display: grid;
	column-gap: 1rem;
	row-gap: 1.5rem;
	align-items: start;
	justify-items: start;
	align-content: start;

	/* because we need a min-height for the stepper children to avoid
	buttons shifting, and because the MuiStepper's component does
	this work for us but doesn't know that we could have a much larger
	block in here a whole new row, not just validation errors, make
	this particular step have an express min-height. If you add rows
	to this onboarding step you'll probably also need to adjust this
	min-height */
	min-height: 20.75rem;

	@media ( min-width: $desktop ) {
		grid-template-columns: 1fr 1fr;
		min-height: 11.5rem;

		button {
			align-self: center;
		}
	}
}

.desktopOnly {
	display: none;
	@media ( min-width: $desktop ) {
		display: initial;
	}
}

.promoCodeSuccess {
	display: flex;
	align-items: center;

	svg {
		fill: $pink;
		height: 1.5rem;
		width: 1.5rem;
		margin-right: 0.5rem;
	}
}

.promoCodeInput {
	.clearButton {
		width: 1.75rem;
		height: 1.75rem;
		padding: 0.25rem;
		display: none;
	}

	&:hover .clearButton,
	&:focus-within .clearButton {
		display: inline-flex;

		.icon {
			width: 0.725rem;
			height: 0.725rem;
		}
	}
}
