@import '../../../../styles/variables';

.container {
	background: $white;
	box-shadow: $boxshadowBasic;
	padding: 0.25rem;
	border-radius: 0.25rem;
	display: flex;
	gap: 0.25rem;
}
