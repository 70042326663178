@import '../../../../../styles/variables';

.container {
	overflow: hidden;
	border: solid 1px rgba( 0, 0, 0, 0.23 );
	border-radius: 0.25rem;
	padding: 1rem;
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
	fill: $darkBlue;

	&.hide {
		fill: $readableGray;
	}
}
