@import '../../../styles/variables';

.container {
	display: grid;
	gap: 1rem;
	max-width: 50rem;
	margin: 0 auto;
	position: relative;

	.loading {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba( 255, 255, 255, 0.5 );
		z-index: 1;
		display: grid;
		justify-content: center;
		align-items: center;
	}
}

.code {
	position: relative;
	background: $mediumGray;
	margin: 1rem 0;
	padding: 2rem;
	word-break: break-all;
	color: $darkBlue;

	&::before {
		content: attr( data-label );
		position: absolute;
		font-size: 1.125rem;
		top: 1.25rem;
		left: 1.25rem;
		color: $darkBlue;
	}
}

.iconButton {
	position: absolute;
	top: 0.75rem;
	right: 0.75rem;
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
	fill: $darkBlue;
}

.buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
}
