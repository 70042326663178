#page-document {
	#document-back-button {
		margin-top: 2rem;

		@media ( min-width: #{$desktop} ) {
			margin-top: 0;
			display: block;
		}
	}

	.document-wrapper {
		width: 100%;
	}

	.document-wrapper--with-border {
		position: relative;
		margin-bottom: 2.5rem;
		border: 1px solid $mediumGray;
		box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
		border-radius: 11px;

		@media ( min-width: #{$desktop} ) {
			margin-bottom: 3.5rem;
		}

		.document-detail__header {
			padding: 3rem 2rem 0 2rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@media ( min-width: #{$desktop} ) {
				max-width: 50.75rem;
				margin: 6.375rem auto 0 auto;
				padding: 0;
				flex-direction: row;
			}

			&--name {
				display: flex;
				flex-direction: column;
			}

			input,
			input:disabled,
			input::placeholder {
				font-size: 2rem;
				font-weight: bold;
				color: $darkBlue;
				opacity: 1;
				background-color: rgba( 0, 0, 0, 0 );
			}

			&--owner {
				display: flex;
				flex-direction: row;
				margin-top: 2rem;

				@media ( min-width: #{$desktop} ) {
					margin-top: 0;
				}

				.profile-image,
				.profile-initials {
					display: flex;
					min-width: 4rem;
					width: 4rem;
					height: 4rem;
				}

				.profile-initials {
					background-color: $lightGray;
				}

				&--text {
					display: flex;
					margin-left: 1.5rem;
					flex-direction: column;
					justify-content: center;

					h2 {
						font-size: 1.375rem;

						@media ( min-width: #{$desktop} ) {
							font-size: 1.688rem;
						}
					}
				}
			}
		}

		.document-detail__stats {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 1.5rem 2rem 0 2rem;

			@media ( min-width: #{$desktop} ) {
				max-width: 50.75rem;
				padding: 0;
				margin: 3rem auto 0 auto;
			}

			&--fileType {
				margin: 0 2rem 2rem 0;
				display: flex;
				flex-direction: column;

				.fileType__details {
					display: flex;
					flex-direction: row;

					svg {
						width: 1.5rem;
						height: 1.5rem;

						* {
							fill: $pink;
						}
					}

					p {
						margin-left: 0.5rem;
					}
				}
			}

			.file-source__details {
				margin: 0 2rem 2rem 0;
				display: flex;
				flex-direction: row;

				svg {
					width: 1.5rem;
					height: 1.5rem;

					* {
						fill: $pink;
					}
				}

				&--fileType-icon {
					margin-right: 0.5rem;
				}

				&--update-button {
					margin-left: 0.5rem;
				}
			}

			&--last-updated {
				margin-right: 10vw;
			}
		}

		.document-detail--shared-with {
			padding: 1.5rem 2rem 0 2rem;
			margin: 0 auto 2rem auto;

			@media ( min-width: #{$desktop} ) {
				max-width: 50.75rem;
				padding: 0;
			}

			.shared-with__button {
				svg {
					width: 1.5rem;
					height: 1.5rem;

					* {
						fill: $pink;
					}
				}
			}

			&__shared-permissions {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.share-widget {
					width: auto;

					& + div {
						margin-left: 1rem;
						cursor: pointer;
					}
				}
			}
		}

		.document-detail__file-viewer {
			border-top: 1px solid $lightGray;
			padding: 1.5rem 2rem 1.5rem 2rem;

			@media ( min-width: #{$desktop} ) {
				padding: 2rem 5rem 2.5rem 5rem;
			}
		}
	}
}

#page-document-footer {
	&.container__footer.container--no-padding {
		width: 100%;
		height: 5.625rem;
		position: sticky;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		margin: 0 0 0 -24px;
		z-index: 4;

		&::after {
			content: none;
		}

		@media ( min-width: #{$desktop} ) {
			position: fixed;
			justify-content: flex-end;
			margin: 0;
			padding: 0 3rem;
			border-top: 1px solid $lightGray;
			box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
		}

		button {
			p {
				font-size: 0.875rem;
			}

			svg {
				width: 1rem;
				height: 1rem;
			}
		}

		button + button {
			margin-left: 1rem;
		}

		#export-document-btn,
		#delete-document-btn {
			max-width: 5rem;

			p {
				display: none;
			}

			svg {
				margin-top: 0.2rem;
				margin-right: auto;
				margin-left: auto;
			}

			@media ( min-width: #{$desktop} ) {
				max-width: none;

				p {
					display: block;
				}

				svg {
					margin-right: 0.5rem;
				}
			}
		}
	}
}
