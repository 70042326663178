@import '../../../../styles/variables';

.icon {
	width: 1.5rem;
	height: 1.5rem;
	color: $green;
	margin-right: 0.333rem;
}

.copy {
	color: $green;
}

.rpcHelpCenterLink {
	:global .MuiTypography-root,
	:global .MuiSvgIcon-root {
		color: $green;
	}
}

.accordionToggle {
	border-top: 1px solid #f2f2f2;

	:global .MuiAccordionSummary-content {
		align-items: center;
	}

	:global .MuiTypography-root {
		letter-spacing: 0;
		font-size: 1.125rem;
		margin-left: 0.4rem;
	}

	:global .MuiAvatar-root {
		width: 3.125rem;
		height: 3.125rem;
	}
}

.accordionToggleActive {
	background-color: #f2f2f2;

	:global .MuiAccordionSummary-content.Mui-expanded {
		margin: 0.75rem 0;
	}
}
