@import '../../styles/variables';

.main {
	padding: 1.5rem;
	position: relative;
	@media ( min-width: $desktop ) {
		padding-top: 2rem;
	}
}

.stepperContent {
	position: relative;
}

.loading {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba( 255, 255, 255, 0.5 );
	z-index: 1;
	display: grid;
	justify-content: center;
	align-items: center;
}

.stepper {
	margin-top: 2rem;
}

.desktopOnly {
	margin: 2rem auto;
	text-align: center;
}
