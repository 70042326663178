@import '../../styles/variables';

.paper {
	overflow: visible;
	filter: drop-shadow( 0 2px 8px rgba( 0, 0, 0, 0.32 ) );

	&::before {
		background-color: $white;
		content: '';
		display: block;
		position: absolute;
		width: 0.625rem;
		height: 0.625rem;
		transform: translateY( -50% ) translateX( -50% ) rotate( 45deg );
		z-index: 0;
	}

	:global .MuiList-root {
		padding: 0;
	}
}

.paperArrowTop {
	margin-top: 0.5rem;
	margin-left: 1.625rem;

	&::before {
		top: 0;
		right: 1rem;
	}
}

.paperArrowLeft {
	margin-top: 1.625rem;
	margin-left: 0.5rem;

	&::before {
		bottom: 1rem;
	}
}
