/* Make clicks pass-through */
#nprogress {
	pointer-events: none;
	height: 0;
}

#nprogress .bar {
	background: #04955f;
	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
	display: block;
	position: absolute;
	right: 0;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #04955f, 0 0 5px #04955f;
	opacity: 1;
	transform: rotate( 3deg ) translate( 0, -4px );
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
	display: block;
	position: fixed;
	z-index: 1031;
	top: 15px;
	right: 15px;
}

#nprogress .spinner-icon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;
	border: solid 2px transparent;
	border-top-color: #04955f;
	border-left-color: #04955f;
	border-radius: 50%;
	animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
	overflow: hidden;
	position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
	position: absolute;
}

@keyframes nprogress-spinner {
	0% {
		transform: rotate( 0deg );
	}

	100% {
		transform: rotate( 360deg );
	}
}
@keyframes nprogress-spinner {
	0% {
		transform: rotate( 0deg );
	}

	100% {
		transform: rotate( 360deg );
	}
}
