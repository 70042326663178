#edit-invoice {
	&-intro {
		width: 100%;
		position: relative;

		#reset-invoice-btn {
			width: 1.5rem;
			height: 1.5rem;
			position: absolute;
			top: 2rem;
			right: 1.5rem;
			cursor: pointer;

			svg {
				* {
					fill: $darkBlue;
				}
			}
		}

		h1 {
			font-size: 2rem;
		}
	}
}

#invoice-edit-submit {
	width: 100%;
	height: 5.625rem;
	position: sticky;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	z-index: 3;

	&::after {
		content: none;
	}

	@media ( min-width: #{$desktop} ) {
		justify-content: flex-end;
		padding: 0 3rem;
		border-top: 1px solid $lightGray;
		box-shadow: 30px 30px 60px 0 rgba( 74, 74, 74, 0.25 );
	}

	button + button {
		margin-left: 1rem;
	}

	#void-invoice-btn,
	#update-invoice-btn {
		max-width: 5rem;

		p {
			display: none;
		}

		svg {
			margin-right: 0;
		}

		@media ( min-width: #{$tablet} ) {
			max-width: none;

			p {
				display: block;
			}

			svg {
				margin-right: 0.5rem;
			}
		}
	}
}

#discount-modal {
	&.modal {
		@media ( min-width: #{$desktop} ) {
			width: 43rem;
		}
	}

	h3 {
		width: 100%;
		margin-top: -1.25rem;
		margin-bottom: 1rem;
		text-align: left;
		font-size: 2.438rem;
	}

	h3 + p {
		text-align: left;
		margin-bottom: 2.375rem;
	}

	.discount__form {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		flex-wrap: wrap;

		&__discount-input {
			width: calc( 100% - 9rem );
			max-width: 19.5rem;
		}

		.toggle {
			width: 9rem;
			margin-left: 1.5rem;
		}

		&__btn-container {
			width: 100%;
			margin-top: 2.5rem;

			button {
				margin: 1rem auto 0;
			}
		}
	}

	.input-validation {
		position: absolute;
	}
}
