@import '../../styles/variables';

.contentWrap {
	width: 100%;
}

.root {
	@media ( min-width: $desktop ) {
		// 7.5 rem is the width of the sideNav
		margin-left: 7.5rem;
		width: calc( 100% - 7.5rem );
	}
}

.sideNavWrap {
	position: fixed;
	left: 0;
	top: 4rem;
	height: 100vh;
	width: 7.5rem;
}
