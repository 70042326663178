.back-button__container {
	margin-bottom: 3rem;
}

.button.back-button {
	width: auto;
	padding: 0.688rem 1rem;

	.button__content-container {
		align-items: center;

		svg {
			margin: 0 0.25rem 0 0;
			transition: transform 200ms ease-in;

			* {
				fill: $gray !important; // @Todo - remove once button component has been updated
				opacity: 1;
			}
		}
	}

	&:hover {
		.button__content-container svg {
			transform: translateX( -5px );

			* {
				fill: #fff !important; // @Todo - remove once button component has been updated
			}
		}
	}
}
