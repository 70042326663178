#page-company-folders {
	.company-folders-index {
		&__header {
			position: relative;

			.document-upload {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
}
