.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.oops {
	padding-bottom: 2rem;
}
