.container {
	display: grid;
	gap: 1rem;
	grid-template-columns: 16.25rem 1px auto;
	position: relative;

	.loading {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba( 255, 255, 255, 0.5 );
		z-index: 1;
		display: grid;
		justify-content: center;
		align-items: center;
	}

	.column {
		display: grid;
		gap: 1rem;
		align-content: start;
		position: relative;
		max-width: 100%;
	}
}
